.banner {
  background-color: #ffcc00;
  padding: 16px 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.message {
  flex: 1;
}
