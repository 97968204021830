.city {
  fill: var(--good-color);
}

.colorless {
  --good-color: rgb(224, 99, 59);
}

.location.plain {
  fill: lightgreen;
}

:global(.dark-mode) .location.plain {
  fill: darkgreen;
}

.location.river {
  fill: lightblue;
}

:global(.dark-mode) .location.river {
  fill: darkblue;
}

.location.mountain {
  fill: #F5DEB3;
}

:global(.dark-mode) .location.mountain {
  fill: #654321;
}

.location.swamp {
  fill: green;
}

.location.unpassable {
  fill: lightgrey;
}