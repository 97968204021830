.colorListContainer {
  display: flex;
  flex-direction: column;
}

.colorList {
  display: flex;
  flex-direction: row;
}

.colorList:first-of-type {
  margin-bottom: 8px;
}

.playerCircle {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 64px;
  background-color: var(--player-color);
  color: var(--player-color-text);
}

.underlined {
  border-bottom: solid 1px black;
}

.playerCircleContainer {
  margin: 4px;
  width: 64px;
}

.captionContainer {
  text-align: center;
}