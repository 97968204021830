.gameListCard {
  display: flex;
  flex-direction: column;
}

.gameList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
}