import { useNotifications } from "@toolpad/core";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ValidationError } from "../../api/error";
import { CreateGameApi, GameApi, GameLiteApi, GamePageCursor, GameStatus, ListGamesApi } from "../../api/game";
import { UserRole } from "../../api/user";
import { PhaseDelegator } from "../../engine/game/phase_delegator";
import { ActionConstructor } from "../../engine/game/phase_module";
import { MapRegistry } from "../../maps";
import { entries, peek } from "../../utils/functions";
import { Entry } from "../../utils/types";
import { assert, assertNever } from "../../utils/validate";
import { useInjected } from "../utils/injection_context";
import { tsr } from "./client";
import { useMe } from "./me";
import { handleError } from "./network";
import { socket, useJoinRoom } from "./socket";
import { useUsers } from "./user";

function getQueryKey(gameId: number | string): string[] {
  return ['games', `${gameId}`];
}

function checkMatch(game: GameLiteApi, entry: Entry<ListGamesApi>): boolean {
  if (entry == null) return true;
  const [key, value] = entry;
  if (value == null) return true;
  switch (key) {
    case 'userId':
      return game.playerIds.includes(value);
    case 'excludeUserId':
      return !game.playerIds.includes(value);
    case 'status':
      return value.includes(game.status);
    case 'gameKey':
      return game.gameKey == value;
    case 'name':
      return game.name.toLowerCase().includes(value.toLowerCase());

    case 'pageCursor':
    case 'pageSize':
    case 'order':
      return true;
    default:
      assertNever(entry);
  }
}

function checkMatches(baseQuery: ListGamesApi, game: GameLiteApi): boolean {
  return entries(baseQuery).every((entry) => checkMatch(game, entry));
}

export function useGameList(baseQuery: ListGamesApi) {
  const tsrQueryClient = tsr.useQueryClient();
  const queryWithLimit: ListGamesApi = { pageSize: 20, ...baseQuery };
  const queryKeyFromFilter =
    Object.entries(queryWithLimit)
      .sort((a, b) => a[0] > b[0] ? 1 : -1).map(([key, value]) => `${key}:${value}`).join(',');
  const queryKey = ['gameList', queryKeyFromFilter];
  const { data, isLoading, error, fetchNextPage, hasNextPage } = tsr.games.list.useInfiniteQuery({
    queryKey,
    queryData: ({ pageParam }) => ({
      query: { ...queryWithLimit, pageCursor: pageParam },
    }),
    initialPageParam: (undefined as (GamePageCursor | undefined)),
    getNextPageParam: ({ status, body }): GamePageCursor | undefined => {
      if (status !== 200) return undefined;
      return body.nextPageCursor;
    },
  });

  handleError(isLoading, error);

  const [page, setPage] = useState(0);

  const games = data?.pages[page]?.body.games;

  const isOnLastPage = data != null && !hasNextPage && data.pages.length - 1 === page;
  const nextPage = useCallback(() => {
    if (isLoading || isOnLastPage) return;
    setPage(page + 1);
    if (data != null && hasNextPage && data.pages.length - 1 === page) {
      fetchNextPage();
    }
  }, [isLoading, setPage, page, hasNextPage, data]);

  const hasPrevPage = page > 0;
  const prevPage = useCallback(() => {
    if (!hasPrevPage) return;
    setPage(page - 1);
  }, [page, setPage, page]);

  useEffect(() => {
    function setGame(game: GameLiteApi) {
      tsrQueryClient.games.list.setQueryData(queryKey, (r) => {
        if (games == null) return r;

        assert(data != null);

        const present = data.pages.some((page) => page.body.games.some((other) => other.id === game.id));

        const matchesQuery = checkMatches(baseQuery, game);
        let newPages: GameLiteApi[][];

        const pages = data.pages.map((page) => page.body.games);
        if (matchesQuery && present) {
          newPages = pages.map((games) =>
            games.map((other) => other.id === game.id ? game : other));
        } else if (matchesQuery) {
          newPages = pages.map((games, index) => {
            const lastOfPrevious = index === 0 ? game : peek(pages[index - 1]);
            return [lastOfPrevious, ...games.slice(0, games.length - 1)];
          });
        } else if (!matchesQuery && present) {
          const pageIndex = pages.findIndex((games) => games.some(other => other.id === game.id));
          newPages = pages.map((games, index) => {
            if (index < pageIndex) return games;
            const firstOfNext = pages[index + 1]?.[0];
            const firstOfNextArr = firstOfNext != null ? [firstOfNext] : [];
            if (index === pageIndex) {
              return games.filter((other) => other.id !== game.id).concat(firstOfNextArr);
            } else {
              return games.slice(1).concat(firstOfNextArr);
            }
          });
        } else {
          newPages = pages;
        }

        const pageParams = newPages.map((_, index) => {
          return newPages.slice(index).flatMap((games) => games.map(({ id }) => id));
        });

        // TODO: fix the typing of this particular method.
        return {
          pageParams,
          pages: newPages.map(games => ({
            status: 200,
            headers: new Headers(),
            body: { games },
          })),
        } as any;
      });
    }
    socket.on('gameUpdateLite', setGame);
    return () => {
      socket.off('gameUpdateLite', setGame);
    };
  }, [queryKey, baseQuery, data]);

  return { games, hasNextPage: !isOnLastPage, nextPage, hasPrevPage, prevPage, isLoading };
}

export function useGame(): GameApi {
  const tsrQueryClient = tsr.useQueryClient();

  const gameId = parseInt(useParams().gameId!);
  const { data } = tsr.games.get.useSuspenseQuery({ queryKey: getQueryKey(gameId), queryData: { params: { gameId } } });

  useJoinRoom();

  useEffect(() => {
    function setGame(game: GameApi) {
      tsrQueryClient.games.get.setQueryData(getQueryKey(game.id), (r) => r && ({ ...r, status: 200, body: { game } }));
    }
    socket.on('gameUpdate', setGame);
    return () => {
      socket.off('gameUpdate', setGame);
    };
  }, []);

  return data.body.game;
}

export function useCreateGame(): { createGame: (game: CreateGameApi) => void, isPending: boolean, validationError?: ValidationError } {
  const { mutate, error, isPending } = tsr.games.create.useMutation();
  const navigate = useNavigate();
  const validationError = handleError(isPending, error);

  const createGame = useCallback((body: CreateGameApi) => mutate({ body }, {
    onSuccess: (data) => {
      navigate('/app/games/' + data.body.game.id);
    },
  }), []);

  return { createGame, isPending, validationError };
}

interface GameAction {
  canPerform: boolean;
  isPending: boolean;
  perform(): void;
}

export function useJoinGame(game: GameLiteApi): GameAction {
  const me = useMe();
  const { mutate, error, isPending } = tsr.games.join.useMutation();
  handleError(isPending, error);

  const perform = useCallback(() => mutate({ params: { gameId: game.id } }), [game.id]);

  const mapSettings = useMemo(() => {
    return MapRegistry.singleton.get(game.gameKey);
  }, [game.gameKey]);

  const canPerform = me != null &&
    game.status == GameStatus.enum.LOBBY &&
    !game.playerIds.includes(me.id) &&
    game.playerIds.length < mapSettings.maxPlayers;

  return { canPerform, perform, isPending };
}

export function useLeaveGame(game: GameLiteApi): GameAction {
  const me = useMe();
  const { mutate, error, isPending } = tsr.games.leave.useMutation();
  handleError(isPending, error);

  const perform = useCallback(() => mutate({ params: { gameId: game.id } }), [game.id]);

  const canPerform = me != null && game.status == GameStatus.enum.LOBBY && game.playerIds.includes(me.id) && game.playerIds[0] !== me.id;

  return { canPerform, perform, isPending };
}

export function useSetGameData() {
  const game = useGame();
  const { mutate, error, isPending } = tsr.games.setGameData.useMutation();
  handleError(isPending, error);

  const setGameData = useCallback((gameData: string) => mutate({ params: { gameId: game.id }, body: { gameData } }), [game.id]);

  return { setGameData, isPending };
}

export function useStartGame(game: GameLiteApi): GameAction {
  const me = useMe();
  const { mutate, error, isPending } = tsr.games.start.useMutation();
  handleError(isPending, error);

  const perform = useCallback(() => mutate({ params: { gameId: game.id } }), [game.id]);

  const mapSettings = useMemo(() => {
    return MapRegistry.singleton.get(game.gameKey);
  }, [game.gameKey]);

  const canPerform = me != null &&
    game.status == GameStatus.enum.LOBBY &&
    game.playerIds[0] === me.id &&
    game.playerIds.length >= mapSettings.minPlayers;

  return { canPerform, perform, isPending };
}

interface ActionHandler<T> {
  emit(data: T): void;
  canEmit: boolean;
  isPending: boolean;
  canEmitUsername?: string;
}

type EmptyActionHandler = Omit<ActionHandler<unknown>, 'emit'> & {
  emit(): void,
};

export function useEmptyAction(action: ActionConstructor<Record<string, never>>): EmptyActionHandler {
  const { emit: oldEmit, canEmit, canEmitUsername, isPending } = useAction(action);
  const emit = useCallback(() => {
    oldEmit({});
  }, [oldEmit]);
  return { emit, canEmit, canEmitUsername, isPending };
}

/** Like `useState` but it resets when the game version changes. */
export function useGameVersionState<T>(initialValue: T): [T, (t: T) => void] {
  const game = useGame();
  const [state, setState] = useState(initialValue);
  const ref = useRef(game.version);
  const externalState = ref.current === game.version ? state : initialValue;
  const externalSetState = useCallback((state: T) => {
    ref.current = game.version;
    setState(state);
  }, [setState, game.version]);
  return [externalState, externalSetState];
}

export function useAction<T extends {}>(action: ActionConstructor<T>): ActionHandler<T> {
  const me = useMe();
  const game = useGame();
  const phaseDelegator = useInjected(PhaseDelegator);
  const notifications = useNotifications();
  const { mutate, isPending, error } = tsr.games.performAction.useMutation();
  handleError(isPending, error);
  const users = useUsers(game.activePlayerId != null ? [game.activePlayerId] : []);

  const actionName = action.action;

  const emit = useCallback((actionData: T) => {
    if ('view' in actionData && actionData['view'] instanceof Window) {
      notifications.show('Error performing action', { autoHideDuration: 2000, severity: 'success' });
      throw new Error('Cannot use event as actionData. You likely want to use useEmptyAction');
    }
    mutate({ params: { gameId: game.id }, body: { actionName, actionData } }, {
      onSuccess() {
        notifications.show('Success', { autoHideDuration: 2000, severity: 'success' });
      }
    });
  }, [game.id, actionName]);

  const actionCanBeEmitted = phaseDelegator.get().canEmit(action);;

  const canEmitUsername = actionCanBeEmitted ? users?.[0]?.username : undefined;
  const canEmit = me?.id === game.activePlayerId && actionCanBeEmitted;

  return { emit, canEmit, canEmitUsername, isPending };
}

export interface UndoAction {
  undo(): void;
  canUndo: boolean;
  isPending: boolean;
}

export function useUndoAction(): UndoAction {
  const game = useGame();
  const me = useMe();
  const notifications = useNotifications();
  const { mutate, error, isPending } = tsr.games.undoAction.useMutation();
  handleError(isPending, error);

  const undo = useCallback(() =>
    mutate({ params: { gameId: game.id }, body: { backToVersion: game.version - 1 } }, {
      onSuccess() {
        notifications.show('Success', { autoHideDuration: 2000, severity: 'success' });
      },
    })
    , [game.id, game.version]);

  const canUndo = game.undoPlayerId != null && game.undoPlayerId === me?.id;

  return { undo, canUndo, isPending };
}

export interface RetryAction {
  retry(): void;
  canRetry: boolean;
  isPending: boolean;
}

export function useRetryAction(): RetryAction {
  const game = useGame();
  const me = useMe();
  const notifications = useNotifications();
  const { mutate, isPending, error } = tsr.games.retryLast.useMutation();
  handleError(isPending, error);

  const retry = useCallback(() =>
    mutate({
      params: { gameId: game.id },
      body: game.version == 1 ?
        { startOver: true } :
        { steps: 1 },
    }, {
      onSuccess() {
        notifications.show('Success', { autoHideDuration: 2000, severity: 'success' });
      },
    }), [game.id, game.version]);

  const canRetry = me?.role == UserRole.enum.ADMIN;

  return { retry, canRetry, isPending };
}