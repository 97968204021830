.playerStats {
  overflow-y: auto;
}

.tableRow td,
.tableRow th {
  padding: 8px;
  white-space: nowrap;
  text-align: left;
  vertical-align: top;
}

.collapsed {
  display: none;
}

@media (max-width: 800px) {
  .collapsed {
    display: table-cell;
  }

  .expanded {
    display: none;
  }
}

svg.user {
  fill: var(--player-color);
  /* background-color: var(--player-color-text);
  border-radius: 50%; */
}