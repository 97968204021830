.log-container {
  position: relative;
}

.log-list {
  height: 200px;
  position: relative;
  background-color: lightgrey;
  overflow-y: auto;
  padding: 8px;
}

:global(.dark-mode) .log-list,
:global(.dark-mode) input {
  background-color: darkgrey;
  color: black;
}

.scroll-to-bottom-container {
  position: absolute;
  width: 100%;
  bottom: 8px;
  left: 8px;
  display: flex;
}

.loadMoreButton {
  width: 100%;
  text-align: center;
}

.scroll-to-bottom {}

.submit-form {
  display: flex;
  flex-direction: row;
}

.submit-form input[type="text"] {
  flex: 1;
}

.time {
  opacity: 0.7;
}

.username {
  font-weight: bold;
}

.message {}