.gameCard {
  min-width: 400px;
}

.active {
  background-color: lightyellow;
}

:global(.dark-mode) .active {
  background-color: #8B4000;
}

.lobby {
  background-color: lightblue;
}

:global(.dark-mode) .lobby {
  background-color: darkblue;
}

.abandoned,
.ended {
  background-color: lightgrey;
}

:global(.dark-mode) .abandoned,
:global(.dark-mode) .ended {
  background-color: darkgrey;
}

.active.activePlayer {
  background-color: lightgreen;
}

:global(.dark-mode) .active.activePlayer {
  background-color: darkgreen;
}